module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"path":"/Users/daniel/WebstormProjects/tolcsva/locales","languages":["en","hu"],"defaultLanguage":"hu","i18nextOptions":{"fallbackLng":"en","interpolation":{"escapeValue":false}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Bor-Kő Udvarház","short_name":"Bor-Kő","start_url":"/","background_color":"#fff","theme_color":"#000","display":"minimal-ui","icon":"src/images/cask.svg","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"81802713b249c2c6d4b61ce7c960ca37"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
